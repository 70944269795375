.soundMeter {
  width: 100%;
  height: 100%;
}
.labelMeter {
  display: inline-block;
}

.valueMeter {
  display: inline-block;
}
.circleMeter {
  width: 15px;
  height: 15px;
  background-color: #64ffda;
  border-radius: 50%;
}
.circleMeterPulse {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  background: #88f1d8b2;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 1s 1;
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #64ffdb88;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(29, 252, 22, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(106, 255, 47, 0);
  }
}

.instant {
  color: #0f9d58;
}
