.browserCopyPath {
  padding: 20px 0px;
}
.browserCopyPathData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.copyPath,
.copyClipboard {
  padding: 0px 20px;
}
.copyPath {
  display: flex;
  align-items: center;
  width: 100%;
}
.copyPath input {
  letter-spacing: 1px;
  color: #b2b2b2;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  border: none;
  text-align: center;
  padding: 10px 0px;
}
.headerInfoBrowser {
  color: #6b7cff;
  font-weight: bold;
  font-size: 38px;
}
.textInfoBrowser {
  text-align: center;
  color: #6f6f6f;
}
.imgBrowserContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.downloadTextBrowser {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
}
.imgCardBrowser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.imgCardBrowser span {
  padding: 0px 20px !important;
  border-radius: 20px !important;
  border: 2px solid #6b7cff !important;
  background: white !important;
}
.imgBrowserTwo .imgCardBrowser {
  width: 50%;
}
.imgDownloadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgDownloadContainer svg {
  margin-right: 2px;
}
.cardContainerBrowsers {
  display: flex;
  justify-content: center;
}
.cardBodyBrowsers {
  width: 30%;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.cardBodyBrowsers > .ant-card-body {
  padding: 10px 0px !important;
}
.downloadBrowserButton {
  padding: 20px 100px !important;
  line-height: 0 !important;
  border-radius: 20px !important;
}
.imgBrowser {
  text-align: center;
}
.imgBrowserTwo {
  display: flex;
  justify-content: space-evenly;
}
.recomendatioBoxBrowser {
  width: 100%;
  margin-top: 30px;
  padding: 15px;
  border-radius: 20px;
  background: #e4e9f3;
}
.recomendationBrowser {
  text-align: center;
}
.downloadBrowser {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}
.copyClipboard {
  width: 100%;
  padding-top: 10px;
}
.copyClipboard button {
  width: 100%;
  border-radius: 20px;
  background: #6a7bff;
  color: white;
  font-weight: bold;
}
@media only screen and (max-width: 1200px) {
  .browserCopyPath {
    padding: 20px 0px;
  }
  .copyClipboard {
    padding: 30px 0px;
  }
}
@media only screen and (max-width: 900px) {
  .cardBodyBrowsers {
    width: 70%;
  }
}
@media only screen and (max-width: 450px) {
  .cardBodyBrowsers {
    width: 90%;
  }
  .imgBrowser {
    padding-top: 0px;
  }
  .downloadBrowser {
    padding: 0px;
  }
  .copyPath {
    padding: 0px;
  }
  .downloadBrowserButton {
    padding: 20px 50px !important;
  }
  .browserCopyPath {
    padding: 10px 0 0 0;
  }
  .recomendationBrowser {
    padding: 10px;
  }
  .headerInfoBrowser {
    font-size: 33px;
  }
  .textInfoBrowser {
    font-size: 12px;
  }
  .recomendationBrowser h3 {
    font-size: 12px;
  }
}
