@font-face {
  font-family: 'Lato';
  src: url('./Fonts/Lato-Regular.woff') format('woff'),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url('./Fonts/Lato-Regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .ant-drawer-content {
  background-color: #f5f5f5 !important;
  opacity: 0.8;
} */

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.fullscreen {
  width: 100%;
  height: 100%;
}
