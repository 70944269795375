.containerFileDrop {
  display: flex;
  flex-direction: column;
}

.fileDropRow {
  justify-content: center;
}

.fileDrop {
  width: 100%;
}

.closedAppointment {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.maskTour {
  color: #7e7e7e38;
}
.container {
  margin: 12px;
}
.buttonsCol {
  padding: 20px;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
}
.rowHeader {
  padding: 15px 55px;
  background: #f9f9f9;
  display: flex;
  justify-content: space-between;
}
.closedAppointment {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.rowHeader .title {
  font-size: 2.5vw;
  color: #c4cde0;
  margin-bottom: -10px;
  font-weight: bold;
}
.rowHeader .subtitle {
  font-size: 1.4vw;
  color: #b2b2b2;
}
.rowError {
  display: flex;
  align-items: center;
}
.rowNoSpace {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}
.testCamera {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}
.testCameraCard {
  display: flex;
  border-radius: 20px;
  width: 100%;
  transition: max-height 0.15s ease-out;
}
.rowNoSpaceExpired {
  display: flex;
}
.largerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.horizontalSpacer {
  margin-left: 5%;
  margin-right: 5%;
}
.title {
  font-size: 1.5vw;
  color: #6b7cff;
}
.codeContainer {
  padding-left: 10px;
}
.codeContainer p {
  font-size: 1.5vw;
  color: #2d3f8c;
  font-weight: bold;
}
.card {
  max-width: 100%;
  min-width: 100%;
  font-family: Inter;
  font-size: 23px;
  line-height: 29px;
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardPatientData {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50%;
}
.cardHeader {
  height: 100%;
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.cardBody,
.cardBodyExpired {
  background-color: white;
  flex: 5;
  padding: 5%;
}
.cardBodyEarly {
  background-color: #fffbe6;
  max-height: 16vh;
  height: 16vh;
  padding: 5%;
  border: 0.1vh solid #faad14;
  border-radius: 3px;
}
.patientNetworkStatus {
  background-color: white;
  border-radius: 2vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 20px 0px 10px 0px;
}
.cardText {
  line-height: 1.5;
  font-size: 1vw;
  color: #2d3f8c;
  text-align: center;
}
.cardSubText {
  font-size: 0.8vw;
  padding-top: 20px;
  color: red;
  font-weight: bold;
  text-align: center;
}
.cardButtonText {
  font-size: 1.1vw;
  font-weight: 500;
}
.cardTextConnect {
  font-size: 0.9vw;
  margin-bottom: 4%;
  margin-left: 5px;
  margin-bottom: 0;
}
.startMeeting {
  background-color: #6a7bff !important;
  color: white !important;
  border-radius: 25px !important;
  min-width: 10px !important;
  min-height: 5vh !important;
  max-height: 5vh !important;
  font-size: 1.1vw !important;
  line-height: 0 !important;
  padding: 20px 30px !important;
  line-height: 0 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  padding: 20px !important;
  width: 100% !important;
}

/* Appointment Survey Component */

.stepsHeader{
  display: flex !important;
}

.stepsContent{
  flex-direction: column !important;
}

.borderNone {
  border: none !important;
}

.sendSurveyButton {
  margin-top: 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ratingTitle {
  color: #6B7CFF;
  font-weight: 320;
  font-size: 2.5vw;
  text-align: center;
}

.ratingSpanIcons {
  
}



.ant-rate-star.ant-rate-star-full,
.ant-rate-star.ant-rate-star-zero,
.ant-rate-star.ant-rate-star-half.ant-rate-star-active {
    transition: transform 0s !important;
}

.ant-rate-star.ant-rate-star-half.ant-rate-star-active:hover {
    transform: scale(0.91) !important;
}

.ant-rate-star.ant-rate-star-full:hover {
    transform: scale(0.91) !important;
}

.ant-rate-star.ant-rate-star-zero:hover {
    transform: scale(0.91) !important;
}




.ratingSubTitle {
  color: #57619A;
  font-weight: 200;
  font-size: 1.7vw;
}

.addCommentOrIssues {
  color: '#99a5ff';
  font-weight: bold;
  cursor: pointer;
  transition-delay: 2s;
}


.stepsActions{
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.stepActionButtons {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}



.sendButton{
  display: flex;
  align-items: center;
  justify-content: center;
}


.cardSurveyData {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  padding: 0px 0px 0px 0px;
}


/* /Appointment Survey Component */


.startMeetingIcon {
  color: #4c5c9d !important;
}

.buttonIcon {
  font-size: 25px !important;
}

.testCameraButton {
  color: white !important;
  border-radius: 25px !important;
  min-width: 10px !important;
  min-height: 5vh !important;
  max-height: 5vh !important;
  font-size: 1.1vw !important;
  line-height: 0 !important;
  background: white !important;
  border: 1px solid #f9f9f9;
  color: #2d3f8c !important;
  font-weight: bold !important;
  padding: 20px !important;
  width: 100% !important;
}
.startMeeting:disabled {
  background-color: #979797 !important;
}
.footerText {
  font-size: 1vw;
  text-align: center;
  color: #808080;
}
.text {
  font-size: 1.2vw;
  color: #6b7cff;
  font-weight: 500;
}

.rowError {
  display: flex;
  align-items: center;
  background-color: #fff1f0;
  border: 0.1vw solid #f5222d;
  border-radius: 0.3vw;
  max-height: 7vw;
  height: 7vw;
  width: 100%;
}
.errorText {
  color: red;
  font-size: 1vw;
  padding-top: 3%;
  padding-left: 3%;
}
.col {
  width: 100%;
  padding-top: 20px;
}
.icon {
  width: 2vw;
  height: 2vw;
}
.iconNotFound {
  width: 2vw;
  height: 2vw;
  margin-left: 5%;
}
.AppointmentsButtons {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.selectOptionsTestCamera {
  padding: 20px;
}
.selectLabelTestCamera {
  color: #5974af;
}
.selectContentLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  color: #5974af;
}
.selectIconCircle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #5974af;
  display: flex;
  justify-content: center;
  align-items: center;
}
.refreshButtonTestCamera {
  width: 100%;
  font-size: 1.1vw;
}
.animationAlert {
  animation: shake 1.2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.videoTestContainer {
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.videoTestContent {
  width: 100%;
  position: relative;
  height: 100%;
  background: black;
  border-radius: 8px;
}
.textSwitchStyle {
  font-weight: 600;
  margin-left: 5px;
}
.cardBodyExpired {
  width: 40%;
  padding: 20px;
}
.videoAndInfoContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 65vh;
  padding: 20px 40px;
}
.videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}
.infoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  overflow-y: auto;
}
.selectContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px 0px;
}
.selectContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectContentIcon {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.connectionStatusContainer {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoFrame {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  object-fit: cover;
  border-radius: 8px;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}
.videoControls {
  position: absolute;
  display: flex;
  bottom: 10px;
  left: 35%;
  width: auto;
  justify-content: center;
  align-items: center;
  background: #ffffffab;
  border-radius: 30px;
}
.videoCircles {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.616);
  display: flex;
  margin: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.videoCircles:hover {
  -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.26);
}
.unactiveCam {
  position: absolute;
  top: 50%;
  left: 40%;
  color: white;
}
.soundDetectionContainer {
  position: absolute;
  bottom: 15px;
  margin: 10px;
  left: 0px;
}
@media screen and (max-width: 1200px) {
  .startMeeting,
  .testCameraButton {
    padding: 20px !important;
  }

  .startMeetingIcon {
    color: #4c5c9d !important;
  }

  .videoContainer {
    width: 60%;
    height: 100%;
  }
  .infoContainer {
    width: 40%;
    height: 100%;
  }
  .videoControls {
    left: 26%;
  }
  .testCamera {
    height: 60vh;
  }
}
@media screen and (max-width: 1100px) {
  .videoContainer {
    width: 50%;
    height: 100%;
  }
  .infoContainer {
    width: 50%;
    height: 100%;
  }
  .videoControls {
    left: 20%;
  }
}
@media (max-width: 1030px) {
  .videoAndInfoContainer {
    flex-direction: column-reverse;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
  }
  .videoContainer {
    width: 100%;
    height: 100%;
  }
  .infoContainer {
    width: 100%;
    height: 100%;
  }
  .videoControls {
    left: 35%;
  }
  .testCamera {
    height: 40vh;
  }
}

@media screen and (max-width: 920px) {
  .testCamera {
    height: 50vh;
    margin-left: 0px;
  }

  .videoControls {
    left: 33%;
  }
  .videoContainer {
    width: 100%;
    height: 100%;
  }
  .infoContainer {
    width: 100%;
    height: 100%;
  }
  .buttonsCol {
    padding: 0px;
  }
  .AppointmentsButtons {
    width: 80%;
  }
  .filesDrawerLeftBox_over {
    padding: 30px;
    padding-top: 50px;
    height: auto;
    width: 50vw;
    background-color: #f5f5f5;
    border-radius: 10px;
    border: 2px dashed #4c5c9d;
  }

  .startMeetingIcon {
    color: #4c5c9d !important;
  }
  .cardBodyExpired {
    width: 80%;
  }
  .cardSubText,
  .selectLabelTestCamera {
    font-size: 2vw;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rowNoSpace {
    display: flex;
    align-items: center;
  }
  .rowNoSpaceExpired {
    display: flex;
  }
  .largerRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5%;
  }
  .horizontalSpacer {
    margin-left: 5%;
    margin-right: 5%;
  }
  .title {
    font-size: 2.3vw;
  }
  .codeContainer p {
    font-weight: bold;
    font-size: 1.5vw;
  }
  .card {
    max-width: 100%;
    min-width: 100%;
    font-family: Inter;
    font-size: 23px;
    line-height: 29px;
    margin-top: 1%;
  }
  .cardBody {
    background-color: white;
    max-height: 16.5vh;
    padding: 5%;
  }
  .cardBodyEarly {
    background-color: #fffbe6;
    max-height: 16vh;
    height: 16vh;
    padding: 5%;
    border: 0.1vh solid #faad14;
    border-radius: 3px;
  }
  .cardpatientData {
    line-height: 85%;
  }
  .cardText {
    line-height: 80%;
    font-size: 1.9vw;
  }
  .cardButtonText {
    margin-top: 1%;
    font-size: 2vw;
  }
  .cardTextConnect {
    font-size: 1.5vw;
    margin-bottom: 4%;
    margin-left: 5px;
    margin-bottom: 0;
  }
  .startMeeting {
    color: white !important;
    min-height: 5vh !important;
    height: 5vh !important;
    max-height: 5vh !important;
  }
  .startMeeting:disabled {
    background-color: #979797 !important;
  }
  .footerText {
    font-size: 1.3vw;
    text-align: center;
    padding-top: 2%;
  }
  .text {
    font-size: 1.5vw;
  }
  .contentContainer {
    min-height: 50vh;
  }
  .errorText {
    color: red;
    font-size: 2vw;
    padding-top: 3%;
    padding-left: 3%;
  }
  .rowError {
    display: flex;
    align-items: center;
    background-color: #fff1f0;
    border: 0.1vw solid #f5222d;
    border-radius: 0.3vw;
    max-height: 8vw;
    height: 8vw;
    width: 100%;
  }
  .colNotFound {
    width: 50vw;
  }
  .icon {
    width: 3.5vw;
    height: 3.5vw;
  }
  .iconNotFound {
    width: 3.5vw;
    height: 3.5vw;
    margin-left: 5%;
  }
  .iconConnection {
    width: 2.5vw;
    height: 2.5vw;
  }

  .selectOptionsTestCamera {
    padding: 15px;
  }
  .startMeeting,
  .testCameraButton {
    font-size: 1.6vw !important;
  }
  .refreshButtonTestCamera {
    font-size: 2vw !important;
  }
  .rowHeader {
    padding: 15px 25px;
  }
  .rowHeader .title {
    margin-bottom: 5%;
    font-size: 3vw;
  }
  .rowHeader .subtitle {
    font-size: 2vw;
  }
}
.loadingContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.loadingWrapper {
  font-size: 24px !important;
}
.loadingWrapper > div {
  margin-top: 15px;
}

@media screen and (max-width: 670px) {
  .videoControls {
    left: 25%;
  }
}
@media (max-width: 600px) {
  .testCamera {
    height: 80vh;
  }
}

@media (max-width: 450px) {
  .videoCircles:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .soundDetectionContainer {
    top: 10px;
    bottom: unset;
  }
  .videoCircles {
    width: 50px;
    height: 50px;
  }
  .testCamera {
    height: 50vh;
  }
  .unactiveCam {
    left: 25%;
    top: 45%;
  }
  .videoContainer {
    width: 100%;
    height: 60%;
  }

  .infoContainer {
    width: 100%;
    height: 40%;
  }
  .videoControls {
    left: 15%;
  }
  .filesDrawerLeftBoxLoading {
    position: relative;
    padding: 30px;
    padding-top: 50px;
    height: auto;
    width: 50vw;
    background: rgba(50, 50, 50, 0.3);
    border-radius: 10px;
    border: 2px solid #f5f5f5;
    transition: 0.5s;
  }

  .filesDrawerLeftBox_over {
    padding: 30px;
    padding-top: 50px;
    height: auto;
    width: 80vw;
    background-color: #f5f5f5;
    border-radius: 10px;
    border: 2px dashed #4c5c9d;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .rowNoSpace {
    display: flex;
    align-items: center;
  }
  .rowNoSpaceExpired {
    display: flex;
  }
  .largerRow {
    display: flex;
    align-items: center;
    margin-top: 5%;
    flex-direction: column;
  }
  .horizontalSpacer {
    margin-left: 5%;
    margin-right: 5%;
  }
  .title {
    font-size: 3vw;
  }
  .codeContainer p {
    font-weight: bold;
    font-size: 3vw;
  }
  .cardPatientData {
    flex-direction: column-reverse;
    align-items: center;
    padding: 20px 0px 0px 0px;
  }
  .cardBodyExpired {
    width: 90%;
    margin: 30% 0 0 0;
  }
  .card {
    max-width: 100%;
    min-width: 100%;
    font-family: Inter;
    font-size: 23px;
    margin-top: 5%;
    height: 100%;
    padding-bottom: 20px;
  }
  .cardHeader {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25%;
  }
  .cardBody {
    background-color: white;
    max-height: 18vh;
    padding: 5%;
  }
  .cardBodyEarly {
    background-color: #fffbe6;
    max-height: 17vh;
    height: 17vh;
    padding: 5%;
    border: 0.1vh solid #faad14;
    border-radius: 3px;
  }
  .cardpatientData {
    line-height: 85%;
  }
  .patientNetworkStatus {
    background-color: white;
    border-radius: 2vw;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 0.4vw;
    padding-bottom: 0.4vw;
    width: fit-content;
    display: flex;
    align-items: center;
  }
  .cardText {
    line-height: 2;
    font-size: 3vw;
    text-align: center;
    padding: 20px;
  }
  .cardButtonText {
    font-size: 3vw;
    padding-top: 1%;
  }
  .cardTextConnect {
    font-size: 2.5vw;
    margin-bottom: 4%;
    margin-left: 5px;
    margin-bottom: 0;
  }
  .startMeeting {
    background-color: #6a7bff !important;
    color: white !important;
    min-height: 5vh !important;
    max-height: 4vw !important;
    height: 4vw !important;
    font-size: 11px !important;
  }
  .startMeeting:disabled {
    background-color: #979797 !important;
    font-size: 11px !important;
  }
  .footerText {
    font-size: 2.2vw;
    text-align: right;
    padding-top: 2%;
    width: 100%;
  }
  .text {
    font-size: 3vw;
  }
  .contentContainer {
    min-height: 50vh;
  }
  .errorText {
    color: red;
    font-size: 3vw;
    padding-top: 5%;
    padding-left: 3%;
  }
  .rowError {
    display: flex;
    align-items: center;
    background-color: #fff1f0;
    border: 0.2vw solid #f5222d;
    border-radius: 0.3vw;
    max-height: 14vw;
    height: 14vw;
    width: 100%;
  }
  .colNotFound {
    width: 65vw;
  }
  .icon {
    width: 6vw;
    height: 6vw;
  }
  .iconNotFound {
    width: 6vw;
    height: 6vw;
    margin-left: 5%;
  }
  .iconConnection {
    width: 4vw;
    height: 4vw;
  }
  .startMeeting,
  .testCameraButton {
    font-size: 2vw !important;
    padding: 20px !important;
  }
  .refreshButtonTestCamera {
    font-size: 3vw !important;
  }
  .AppointmentsButtons {
    display: flex;
    align-items: center !important;
    margin-left: 0px;
    width: 100%;
  }
  .buttonsCol {
    padding: 0px 5px;
  }
  .cardSubText {
    font-size: 3vw;
    text-align: center;
  }
  .selectLabelTestCamera {
    font-size: 3vw;
  }
  .selectOptionsTestCamera {
    padding: 18px 0px;
  }
  .rowHeader {
    padding: 15px;
  }
  .rowHeader .title {
    margin-bottom: 5%;
    font-size: 5vw;
  }
  .rowHeader .subtitle {
    font-size: 3vw;
  }
}

.containerFilesDrawer {
  margin-top: 13vh !important;
}

.filesDrawerLeftBox {
  position: relative;
  padding: 30px;
  padding-top: 50px;
  height: auto;
  width: 28vw;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 2px solid #f5f5f5;
  transition: 0.5s;
}

.filesDrawerLeftBoxLoading {
  position: relative;
  padding: 30px;
  padding-top: 50px;
  height: auto;
  width: 28vw;
  background: rgba(50, 50, 50, 0.3);
  border-radius: 10px;
  border: 2px solid #f5f5f5;
  transition: 0.5s;
}

.filesDrawerLeftBox:hover {
  cursor: pointer;
}

.filesDrawerLeftBox_over {
  padding: 30px;
  padding-top: 50px;
  height: auto;
  width: 28vw;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 2px dashed #4c5c9d;
}

.filesDrawerLeftBoxIcon {
  color: #4c5c9d !important;
  font-size: 60px;
}

.filesDrawerRightBox {
  padding: 10px;
  height: auto;
  width: 28vw;
}

.filesDrawerRightBoxListItemMeta {
  background: #fbfbfb;
  border-bottom: none !important;
  padding: 5px !important;
  margin-bottom: -4px !important;
}

.filesDrawerRightBoxFileItem {
  padding: 10px;
  background: #fbfbfb;
  margin-bottom: 17px;
}

.filesDrawerRightBoxFileItemDeleting {
  padding: 10px;
  /* background: #fbfbfb; */
  margin-bottom: 17px;
  background: linear-gradient(-45deg, #f00, #fbfbfb);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 1s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.filesDrawerRightBoxFileItem:hover {
  background: #efefef;
  box-shadow: 0px 5px 10px 1px rgba(227, 227, 227, 1);
}

.filesDrawerRightBoxFileItemIcon {
  color: #dbdbdb !important;
  font-size: 20px;
}

.filesDrawerRightBoxFileItemName {
  color: #a5b0fd !important;
  font-weight: bold !important;
}

.filesDrawerRightBoxFileItemDeleteButton {
  font-size: 20px;
  color: #cccccc !important;
}

.filesDrawerRightBoxFileItemProgressBar {
  margin-top: -27px;
}

.dnd_over {
  border: 2px dashed #4c5c9d;
}

.dnd_leave {
  border: 2px solid #ffffff;
}

.hidden {
  display: none;
}

.limitText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10px;
}

@media (max-width: 420px) {
  .videoControls {
    left: 18%;
  }
}
@media (max-width: 380px) {
  .videoControls {
    left: 14%;
  }
  .unactiveCam {
    left: 21%;
  }
}
@media (max-width: 320px) {
  .videoControls {
    left: 7%;
  }
  .unactiveCam {
    left: 16%;
  }
}
