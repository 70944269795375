.welcomeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.welcomeTitle {
  font-family: Roboto;
  font-weight: 500;
  font-size: 47px;
  line-height: 37px;
  color: #49649f;
  margin: 30px 0;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
  min-width: 35vw;
}

.title {
  font-size: 1.5vw;
  color: #2e4a98;
  flex: 1;
}

.divider {
  flex: 1;
  display: flex;
  justify-content: center;
}

.horizontalSpacer {
  margin-left: 5%;
  margin-right: 5%;
}

.welcomeInfo {
  font-family: Roboto;
  font-weight: 500;
  font-size: 35px;
  line-height: 50px;
  color: #49649f;
  text-align: center;
  max-width: 35vw;
  margin: 30px 0;
}

@media screen and (max-width: 920px) {
  .welcomeTitle {
    font-size: 65px;
    line-height: 50px;
    margin: 40px 0;
  }

  .welcomeInfo {
    font-size: 35px;
    line-height: 50px;
    max-width: 80vw;
    margin: 40px 0;
  }

  .row {
    min-width: 80vw;
    margin: 40px 0;
  }

  .title {
    font-size: 4vw;
  }
}

@media screen and (max-width: 450px) {
  .welcomeTitle {
    font-size: 20px;
  }

  .welcomeInfo {
    font-size: 17px;
    line-height: 25px;
    max-width: 80vw;
  }

  .row {
    min-width: 80vw;
  }

  .title {
    font-size: 4vw;
  }
}
