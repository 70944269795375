.filesCardDrawer {
  /* width: 90%; */
  margin: 30px;
}
.filesCardDrawerRoom {
  width: 50%;
  margin: 30px;
}

.filesCard {
  position: absolute;
  background: white;
  border-radius: 25px;
  top: 12%;
  left: 5%;
  max-width: 450px;
  width: 35%;
  max-height: 217px;
  height: 27%;
  display: flex;
  flex-direction: column;
}

.filesCardTitle {
  color: #2d3f8c;
  border-bottom: solid 1px #e4e4e4;
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
}

.filesContainer {
  width: 95%;
  height: 120px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.filesItems {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  margin-left: 2vh;
  list-style: none;
  padding-right: 10px;
}

.filesItems a {
  margin-left: 5px;
  font-family: Lato;
  padding-left: 10px;
}

.centerSpin {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.uploadIcon {
  margin: 10px;
}

.uploadIcon > svg {
  fill: blue;
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (max-width: 919px) {
  .filesCard {
    top: 1%;
    left: 31%;
  }
  .filesCardDrawer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .filesCardTitle {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .filesCard {
    width: 45%;
  }
}

@media screen and (max-width: 520px) {
  .filesCard {
    top: 1%;
    left: 45%;
  }
}
